export const API_URL = 'https://devorder.crowndigital.io/admin/web/v1/';
export const API_URL2 = 'https://devorder.crowndigital.io/web/';
//export const LOCAL_PAYMENT_API_URL = "https://192.168.10.11";
export const LOCAL_PAYMENT_API_URL = "http://localhost";
export const LOCAL_PAYMENT_PORT = "5000";
export const SUICA_SERVER = "7700";
export const PRINT_TIMEOUT = 20000;
export const telemetryTimeout = 5000;
export const setIntervalTimeOut =1000;
export const QRcodeTimeout = 10000;
export const cart_time = 30;
export const payment_timer = 30;
export const modalTimeout = 6000;
export const PRINT_API_URL = "http://localhost:3333/?purl=";
export const KIOSK_AUTO_LOGIN ="https://xsalexandra.crowndigital.io/kioskautologin/8be1e7b34a90d63a25979c645172b2a2/b763e446b9516ba0838c1f83276dd930";
export const page_redirection_time = 30;
export const apitimeout = 29000;
export const cancel_timeout = 10000;
export const enable_test_buttons = true;
export const payment_failed_modal = 30;
export const settlement_timer = 30;
export const terminalCheck = 10000;
export const terminal_check_enable = false;
export const menu_items_timer = 20000;
export const images_display_timer = 15000;
export const DOMAIN_URL = "https://xsalexandra.crowndigital.io";
export const qr_page_redirection_time = 60;
export const DEBUGG_MODE_ENABLE = true;
export const CUP_MODAL_TIMER = 30;
export const place_cup_page_timer = 60;
export const apiSigningKey = '3JZqRZ6RvUOEBT92nmNLyA';
export const dbs_qr_timer = 120;
export const dbs_qr_timer_res_wait = 60;
