import { Component, OnInit } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from '../services/localstore.service';
import { UtilityService } from '../services/utility.service';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { KioskapiService } from '../services/kioskapi.service';
import { images_display_timer, page_redirection_time, setIntervalTimeOut, terminalCheck } from '../common/api';
import { TranslateConfigService } from '../services/translate-config.service';
import * as moment from 'moment';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.page.html',
  styleUrls: ['./scan.page.scss'],
})
export class ScanPage implements OnInit {
  is_maintaince_mode_on = 'false';
  appSettings: any;
  shop: any;
  menuItems={};
  totalAmount: number;
  settings={};
  orderType = "";
  currency = "¥";
  showDecimal= true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment:any;
  timer_start=true;
  telemetry_poller:any;
  terminal_modal;
  is_restaurant_closed_status = 'NO';
  store_close_status;
  restaurant_mode;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  store_info;
  is_ella_maintaince_mode_on:boolean = false;
  obj_restaurantDetails;
  kiosk_start_screen_image:any;
  kiosk_scan_screen_gif:any;
  display_images:any;
  menuItems_timer:any;
  enable_for_kiosk="";
  QR_code_id = 'https://order.ellaapp.com/admin/web/referfriend';
  useLocalGraphics = false;
  useOldDesign='';
  showQRCodePopup = false;
  machine_status;
  ellaMachineHasError = false;
  debuggModeSettings;
  qrCodeInterval;
  showToastMessagePopup = false;
  showToastMessageText = '';
  showEllaSoldOutPopup = false;
  qrTopBarAnimationTimer = '';


  constructor(private router: Router,
    private store:LocalStoreService,
    private utilityService: UtilityService,
    private kioskauthservice:KioskauthService,
    private kiosk_apiService : KioskapiService,
    public alertController: AlertController,
    private translateConfigService: TranslateConfigService) { }
  
  ngOnInit() {
    this.animationClassForQRModalTopBar = '';
    this.qrTopBarAnimationTimer = page_redirection_time+'s';
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    // this.kiosk_apiService.telemetry();
    // this.telemetry_poller = setInterval(
    //   ()=>{
    //    this.kiosk_apiService.telemetry()
    //   }
    // , terminalCheck);
    this.store.setUserCart([]);
    this.is_restaurant_closed();
        this.getRestaurantDetails();
        this.store_close_status = setInterval(
          () => {
            this.is_restaurant_closed();
          }
          , 10000);
    
        this.restaurant_mode = setInterval(
            () => { 
              this.getRestaurantDetails();
            }
            , 12000);
            this.getUserDataOrQRCodeData();
            this.qrCodeInterval = setInterval(
              ()=>{
              this.getUserDataOrQRCodeData();
              }
            , 1000);
            // this.display_images =setInterval(()=>{
            //   this.getAppSettings();
            // },images_display_timer)
      
    this.getAppSettings();
    this.kiosk_apiService.logEvents("SCAN");
    this.kiosk_apiService.audit_log("SCAN");
  }

 
  getRestaurantDetails(){
   this.obj_restaurantDetails = this.kiosk_apiService.restaurant_Details()
        .subscribe((res) => {
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant',res.aaData);
          this.store_info = res.aaData;
          this.enable_for_kiosk =  res.aaData.enable_kiosk
          if(this.store_info.master_restaurant_status == "ELLA_M_ON"){
            this.is_ella_maintaince_mode_on = true;
          }
          else{
            this.is_ella_maintaince_mode_on = false;
          }
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          //this.getAppSettings();
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if (res?.aaData?.ella_orders_blocked && (res?.aaData?.ella_orders_blocked == 'true' || res?.aaData?.ella_orders_blocked == true)) {
            this.showEllaSoldOutPopup = true;
          } else {
            this.showEllaSoldOutPopup = false;
          }
          if(this.shop.master_restaurant_status == 'M_ON'){
          this.is_maintaince_mode_on ='true';
          }
          else{
          this.is_maintaince_mode_on = 'false';
          }
        } 
    }, 
      // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader.
      err =>
          {
            this.technical_error();
    });
  }
  
  getAppSettings() {

   this.kiosk_apiService.appsettings().subscribe(response => {
      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;

        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description :  environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        this.kiosk_start_screen_image = response.aaData.app_settings.app_store_scan_description_kiosk;
        this.kiosk_scan_screen_gif = response.aaData.app_settings.kiosk_scan_screen_gif;
        if (response.aaData.app_settings.kiosk_use_old_design) {
          this.useOldDesign = 'true';
        } else {
          this.useOldDesign = 'false';
        }
        if (response.aaData.app_settings.kiosk_use_local_graphic) {
          this.useLocalGraphics = response.aaData.app_settings.kiosk_use_local_graphic;
        } else {
          this.useLocalGraphics = false;
        }
      }
    }, err => {

    });
  }

  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
  }

  obj_restaurantstatus;
  is_restaurant_closed(){
   this.obj_restaurantstatus = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
          (res) => {
              if (res.statusCode == 200) {
                if(res.aaData == true) {
                  this.is_restaurant_closed_status= "NO";
                } else {                  
                  this.is_restaurant_closed_status= "YES";
                }
              } 
          },
          err =>
          {
            this.technical_error();
          }
      );
 }

 ionViewDidLeave() {
  clearInterval(this.restaurant_mode);
  clearInterval(this.store_close_status);
  clearInterval(this.telemetry_poller);
  clearInterval(this.machine_status);
  clearInterval(this.qrCodeInterval);
  this.obj_restaurantstatus.unsubscribe();
  this.obj_restaurantDetails.unsubscribe();
  clearInterval(this.terminal_modal)
  clearInterval(this.display_images)
  this.animationClassForQRModalTopBar = '';
  this.qrTopBarAnimationTimer = page_redirection_time+'s';

}

async technical_error(){
  // const alert =  this.alertController.create({
  //   cssClass: 'my-custom-class',
  //   mode: 'md',
  //   header: 'Oops!',//'Please Tap Again',
  //   message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
  //   buttons: [
  //   {
  //    text: '',
  //    cssClass: 'cross',
  //   handler: () => {
  //    this.alertController.dismiss();
  //   this.router.navigateByUrl('/scan');
  //    }
  //  }
  //   ]
  //   });
     setTimeout(() => { this.router.navigateByUrl('/scan'),this.alertController.dismiss();},5000);
    //  (await alert).present();
  }
  redirectToHome(){
    clearInterval(this.qrCodeInterval);
    this.showQRCodePopup = false;
    this.store.set('isEmployeeLogin','false')
    const userSessionId = Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5) + moment().format('HHMMSS');;
    this.store.set('userSessionId', userSessionId);
    this.router.navigate([`home`]);
  }
  askForQR() {
    // this.showToastMessage('You have used your maximum cup limit. Please return the cup.')
    this.showToastMessagePopup = false;
    this.showQRCodePopup = true;
    this.qrTopBarAnimationTimer = page_redirection_time+'s';
    this.resetQrModalTimer();
    // let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
    //   let payload = {
    //     restaurant_id : master_restaurant_uuid,
    //   }
    //   this.utilityService.apiReq('post', 'ssouserinformation/staff_logout', payload).subscribe((response) => {
    //     console.log('autoLogoutUser Employee'); 
    //     this.showQRCodePopup = true;
    //     this.qrCodeInterval = setInterval(
    //       ()=>{
    //       this.getUserDataOrQRCodeData()
    //       }
    //     , 1000);
    //     this.resetQrModalTimer();
    // })
    
  }
  scanQrModalTimer;
  scanQrModalTimerCounter;
  animationClassForQRModalTopBar = '';
  resetQrModalTimer() {
    this.scanQrModalTimer = page_redirection_time;
    if (this.scanQrModalTimer = page_redirection_time) {
      this.animationClassForQRModalTopBar = '';
      setTimeout(() => {
        this.animationClassForQRModalTopBar = 'animationClassForTopBar';
      }, 300);
      this.scanQrModalTimerCounter = setInterval(() => {
        this.scanQrModalTimer -= 1;
        if (this.scanQrModalTimer == 0) {
          // clearInterval(this.qrCodeInterval);
          clearInterval(this.scanQrModalTimerCounter);
          this.showQRCodePopup = false;
          this.qrTopBarAnimationTimer = page_redirection_time+'s';
          return false;
        }
      }, setIntervalTimeOut);
    }
  }
  ionViewWillEnter() {
    this.debugg_mode_setting();
    this.ellaMachineHasError = false;
  }
  debugg_mode_setting() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
    this.kiosk_apiService.restaurant_Details()
      .subscribe(
        (res) => {
          this.debuggModeSettings = res.aaData.module_permissions;
          if (this.debuggModeSettings?.enable_debugg_mode == false) {
            this.checkMachineStatus();
            this.machine_status = setInterval(
              ()=>{
               this.checkMachineStatus()
              }
            , 3000);
          }
        },
        error => {
        }
      );
  }
  getUserDataOrQRCodeData() {
    if (!this.showEllaSoldOutPopup) {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
    let payload = {
      master_restaurant_uuid:master_restaurant_uuid
    }
    this.kiosk_apiService.apiReq('post', 'xs/stafflogincheck', payload)
    .subscribe((response) => {
      
      if (response?.status == true) {
        console.log("xs/stafflogincheck", response)
        //here will be the code of scanned QR user
        //Set user name & id in local 
        if (response?.aaData) {
          clearInterval(this.qrCodeInterval);
          clearInterval(this.scanQrModalTimerCounter);
          this.store.set('isEmployeeLogin','true');
          this.store.set('loggedInEmployeeData',response?.aaData);
          this.store.set('userOrderNumber', response?.aaData?.order_number);
          this.store.set('hasAddedIce', response?.aaData?.hasAddedIce);
          this.store.set("orderId", response?.aaData['order_info_uuid']);
          const userSessionId = Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5) + moment().format('HHMMSS');;
          this.store.set('userSessionId', userSessionId);
          this.router.navigate([`payment-success`]);
        } else {
          this.showQRCodePopup = false;
          // clearInterval(this.qrCodeInterval);
          clearInterval(this.scanQrModalTimerCounter);
          this.showToastMessage(response?.message)
        }
        
      } else {
        if (response?.statusCode == 449) {
          this.showQRCodePopup = false;
          // clearInterval(this.qrCodeInterval);
          clearInterval(this.scanQrModalTimerCounter);
          this.showToastMessage(response?.message)
        }
      }
    },
      error => {
      }
    );
    }
  }

  showToastMessage(textMessage) {
    this.showToastMessagePopup = true;
    this.showToastMessageText = textMessage;
    setTimeout(() => {
      this.showToastMessagePopup = false;
    }, 20000);
  }
  checkMachineStatus() {
    if (!this.showEllaSoldOutPopup) {
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    this.kiosk_apiService.apiReq('get', 'masterrestaurant/gethealthcheckrasberrypi?master_restaurant='+master_restaurant_uuid)
    .subscribe((response) => {
      if (response?.statusCode == 200) {
        if (response?.aaData?.syrup_weight_sensor == true && response?.aaData?.cup_sensor == true && 
          response?.aaData?.camera == true) {
          // Working Fine
          this.ellaMachineHasError = false;
        } else {
          this.ellaMachineHasError = true;
        }
      }
    },
      error => {
      }
    );
    }
  }
}
